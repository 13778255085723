//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SearchResults from '@/components/Search/SearchResults'
import LoadingSpinner from '@/components/LoadingSpinner'
import EmptyMessage from '@/components/EmptyMessage'

export default {
    name: "Search",
    data() {
      return {
        currentSearch: '',
        isSearching: true,
        page: 1,
        search_options: [0,1,2,3],
        isEmpty: true,
        searchResult: null
      }
    },
    methods: {
      search() {
        if (this.currentSearch === undefined) {
          this.isSearching = false;
          return;
        }
        this.isSearching = true;
        this.$http({
          url: 'search',
          method: 'post',
          data: {
            search_options: this.search_options,
            q: this.currentSearch,
            currentPage: this.page
          }
        }).then((response) => {
          if (response.data['status'] == 'results_found') {
            this.isEmpty = false;
            this.searchResult = response.data['body']['results'];
          }
          if (response.data['status'] == 'no_result_found') {
            this.isEmpty = true;
          }
        })
        this.isSearching = false;
      }
    },
    components: {
      SearchResults,
      LoadingSpinner,
      EmptyMessage
    },
    created() {
      this.currentSearch = this.$route.query.q;
      this.search();
    },        
    watch: {
      '$route.query.q'() {
        this.currentSearch = this.$route.query.q;
        this.search();
      }
    }
}
